import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div id="main">
      <div style={{ background: "#FFF" }}>
        <a href="/">
          <img
            id="logo"
            src={data.file.publicURL}
            alt="Enosh Bible Camp Logo"
          />
        </a>
      </div>
      <div className="diagonal">
        <svg viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
        </svg>
        <Link className="button--anchor" to="/">
          Return to Home
        </Link>
      </div>
      <div className="info">
        <h3>About Enosh Bible Camp</h3>
        <p>
          The name of our camp comes from Genesis 4:26. After Enosh was born to
          Seth, the Bible records that then men began to call on the name of the
          Lord. Our goal is to see souls reached with the gospel of Christ so
          that they would truly call on the name of the Lord.
        </p>
        <br />
        <br />
        <p>
          Enosh Bible Camp was founded in 2008 to provide a FREE day camp to the
          children of Topeka as a ministry of Topeka Gospel Chapel, a
          non-denominational group of believers committed to Jesus Christ.
        </p>
      </div>
      <div>
        <svg viewBox="0 0 100 7" preserveAspectRatio="none">
          <polygon points="0,7 100,7 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info info--white">
        <h3>Enosh Bible Camp Doctrinal Beliefs</h3>
        <p>
          We Believe the Bible is the inspired Word of God or "God Breathed." It
          is the only infallible rule of authority in all matters of faith and
          practice.
        </p>
        <span>2 Timothy 3:16, Hebrews 4:12, 2 Peter 1:20-21</span>
        <br />
        <br />
        <p>
          We believe there is one living and true God, eternally existent in
          three Persons - Father, Son and Holy Spirit, each equally deserving
          reverence and obedience.
        </p>
        <span>Deuteronomy 6:4, Matthew 28:19, 2 Corinthians 13:14</span>
        <br />
        <br />
        <p>
          We believe in the eternal deity of the Lord Jesus Christ, that He is
          true God and true man begotten of the Holy Spirit, born of a virgin,
          and that He has a perfect and sinless humanity.
        </p>
        <span>
          Matthew 1:23-25, John 1:1, Philippians 2:6, 1 John 3:5, Isaiah 9:6
        </span>
        <br />
        <br />
      </div>
      <div>
        <svg viewBox="0 0 100 5" preserveAspectRatio="none">
          <polygon points="100,0 0,5 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info">
        <p>
          We believe that man is dead in sin through his fall in the Garden of
          Eden and that man is absolutely unable to save himself from eternal
          punishment by his own works.
        </p>
        <span>Genesis 3, Romans 3:23, Ephesians 2:8-9</span>
        <br />
        <br />
        <p>
          We believe in the bodily resurrection of the just and the unjust, the
          everlasting blessedness of the saved and the everlasting conscious
          punishment of the lost.
        </p>
        <span>1 Thessalonians 4:17, Revelation 20:11-15</span>
        <br />
        <br />
        <p>
          We believe in the finished work of Christ on the Cross of Calvary,
          through which sacrifice Christ has effected complete and eternal
          redemption, God setting His seal of approval on the work of Christ by
          raising Him from among the dead, enthroning Him at His own right hand
          in Heaven and that Jesus Christ is the Head of the Church, the Lord of
          the individual, and High Priest over the House of God and Advocate in
          the family of God.
        </p>
        <span>
          John 10:15, Romans 3:23-24, Romans 5:8, Colossians 1:15-18, 1 Peter
          2:24
        </span>
        <br />
        <br />
      </div>
      <div>
        <svg viewBox="0 0 100 15" preserveAspectRatio="none">
          <polygon points="0,15 100,15 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info info--white">
        <p>
          We believe in the immediate and eternal salvation by grace through
          faith of every person who believes on Christ and by faith rests in His
          finished work. That the finished work of the Cross is the only
          righteous ground on which a Holy God can forgive sins.
        </p>
        <span>Romans 3:23, 6:23, Ephesians 2:8-9, 1 John 1:8</span>
        <br />
        <br />
        <p>
          We believe every true child of God is indwelt and sealed with the Holy
          Spirit and is safe and secure for all eternity.
        </p>
        <span>
          John 10:27-29, Romans 8:9-30, Philippians 1:6, 2 Timothy 1:12, Hebrews
          12:5-11
        </span>
        <br />
        <br />
        <p>
          We believe in the deity of the Holy Spirit who convicts of sin; lives
          within, seals and empowers every true believer; and Who directs the
          worship and service of the church, giving spiritual gifts to all true
          believers for the building up of the church.
        </p>
        <span>John 16:8, Acts 1:8, Ephesians 1:13, 1 Corinthians 12:4-13</span>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default IndexPage
